import { Link } from 'react-router-dom'

import './BlogCard.css'

function BlogCard({ imgUrl, title, desc, link }) {
  return (
    <div className='blog_card'>
      <Link to={link}>
        <div className="img_box">
          <img className='w_100' src={imgUrl} alt="thumb" />
        </div>
        <h4>{title}</h4>
        {/* <div
          dangerouslySetInnerHTML={{ __html: desc }}
        /> */}
        <p>{desc}</p>
      </Link>
    </div>
  )
}

export default BlogCard