
import PlayButton from '../../../../../assets/icons/play-button.svg'

import './SingleVideoCard.css'

function SingleVideoCard({event, imgUrl, date, title}) {
  return (
    <button className='single_video_card play_icon_box' onClick={event}>
        <img src={imgUrl} alt="thumb" className='thumb_img' />
        <img src={PlayButton} alt="icon" className='play_icon' />
        <div className="content">
            <span>{date}</span>
            <p>{title}</p>
        </div>
    </button>
  )
}

export default SingleVideoCard