import { useEffect, useState } from 'react'
import axios from 'axios'
import { IMAGE_URL, VIDEOS_API, VIDEO_CATEGORIES_API } from '../../../Utilities/APIs/APIs'
// import VideoImg1 from '../../../assets/images/video-img-1.png'
// import VideoImg2 from '../../../assets/images/video-img-2.png'
// import VideoImg3 from '../../../assets/images/video-img-3.png'
import VideoPlayer from '../../Elements/VideoPlayer/VideoPlayer'
import Modal from '../../Layouts/Modal/Modal'

import VideoCard from './VideoCard/VideoCard'

import './VideoGallery.css'
import VideoCategory from './VideoCategory/VideoCategory'

function VideoGallery() {

    const [showPlayer, setShowPlayer] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [videos, setVideos] = useState(null);
    const [videoCategories, setVideoCategories] = useState(null);
    const [targetID, setTargetID] = useState('');

    useEffect(() => {
        async function getVideos() {
            if (targetID === '') {
                const { data } = await axios.get(VIDEOS_API)
                setVideos(data)
            } else {
                const { data } = await axios.get(VIDEOS_API + 'getVideosFromCategory/' + targetID)
                setVideos(data)
            }
        }

        getVideos()
    }, [targetID])

    useEffect(() => {
        async function getVideoCategories() {
            const { data } = await axios.get(VIDEO_CATEGORIES_API)
            setVideoCategories(data)
        }

        getVideoCategories()
    }, [])

    const handleSetUrl = (url) => {
        setVideoUrl(url);
        setShowPlayer(true);
    }

    const handleModalClose = () => {
        setVideoUrl('');
        setShowPlayer(false);
    }


    return (
        <>
            <section className="video_page page_padding">
                <div className="container">
                    <h1 className="page_title">My video <br /> gallery</h1>
                    <div className="grid_box video_categories">
                        <VideoCategory
                            name='All'
                            isActive={(targetID === '') ? 'active' : ''}
                            hadleClick={() => setTargetID('')}
                        />
                        {videoCategories?.map(videoCategory => (
                            <VideoCategory
                                key={videoCategory._id}
                                id={videoCategory._id}
                                name={videoCategory?.name}
                                isActive={(targetID === videoCategory._id) ? 'active' : ''}
                                hadleClick={() => setTargetID(videoCategory._id)}
                            />
                        ))}

                    </div>
                    <div className="grid_box video_cards">
                        {videos?.map(video => (
                            <VideoCard
                                key={video._id}
                                handleClick={() => handleSetUrl(video?.link)}
                                imgUrl={IMAGE_URL + video?.image}
                                title={video?.name}
                            />
                        ))}

                        {/* <VideoCard
                            handleClick={() => handleSetUrl('uZFfNMfHHCA')}
                            imgUrl={VideoImg2}
                            title='Neon Nights: An 80s-Inspired Synthwave Music Video'
                        />
                        <VideoCard
                            handleClick={() => handleSetUrl('qNSv9I0Flac')}
                            imgUrl={VideoImg3}
                            title='Lost in the Stars: A Cosmic Journey Music Video'
                        />
                        <VideoCard
                            handleClick={() => handleSetUrl('uZFfNMfHHCA')}
                            imgUrl={VideoImg2}
                            title='Neon Nights: An 80s-Inspired Synthwave Music Video'
                        />
                        <VideoCard
                            handleClick={() => handleSetUrl('qNSv9I0Flac')}
                            imgUrl={VideoImg3}
                            title='Lost in the Stars: A Cosmic Journey Music Video'
                        />
                        <VideoCard
                            handleClick={() => handleSetUrl('uZFfNMfHHCA')}
                            imgUrl={VideoImg2}
                            title='Neon Nights: An 80s-Inspired Synthwave Music Video'
                        /> */}
                    </div>
                </div>
            </section>
            {showPlayer &&
                <Modal
                    handleClose={handleModalClose}
                >
                    <VideoPlayer
                        videoUrl={videoUrl}
                    />
                </Modal>
            }
        </>
    )
}

export default VideoGallery