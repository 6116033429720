import { Route, Routes } from 'react-router-dom';

import './App.css';

//imported components
import Navbar from './Sections/Navbar/Navbar';
import Home from './Screens/Home/Home';
import Footer from './Sections/Footer/Footer';

import About from './Screens/About/About';
import Blogs from './Screens/Blogs/Blogs';
import SingleBlog from './Screens/SingleBlog/SingleBlog';
import VideoGallery from './Screens/VideoGallery/VideoGallery';
import ScrollTop from './ScrollTop';

function App() {
  return (
    <>
      <ScrollTop />
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/blogs' element={<Blogs />} />
        <Route path='/single-blog/:id' element={<SingleBlog />} />
        <Route path='/video-gallery' element={<VideoGallery />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
