import QuoteImg from '../../../../../assets/images/quote-img.png'

import './QuoteBanner.css'

function QuoteBanner() {
  return (
    <section className='quote_banner'>
        <img src={QuoteImg} alt="quote" className='w_100' />
    </section>
  )
}

export default QuoteBanner