import { Link } from "react-router-dom"

function Logo({ imgSrc }) {
  return (
    <Link to='/'>
      <img className='w_100' src={imgSrc} alt="logo" />
    </Link>
  )
}

export default Logo