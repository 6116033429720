import './VideoCategory.css'

function VideoCategory({ isActive, name, hadleClick }) {
    return (
        <button
            className={`video_category ${isActive && 'active'}`}
            onClick={hadleClick}
        >
            {name}
        </button>
    )
}

export default VideoCategory