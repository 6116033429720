import { RiCloseLine } from 'react-icons/ri'

import './Modal.css';

function Modal({ handleClose, children }) {
  return (
    <div className={`modal`}>
      <div className="modal_backdrop" onClick={handleClose}></div>
      <div className={`modal_dialog modal_dialog_centered modal_dialog_scrollable`}>
        <div className="modal_content">
          <button type='button' className='modal_close' onClick={handleClose}><RiCloseLine /></button>
          <div className="modal_body">{children}</div>
        </div>
      </div>
    </div>
  )
}
export default Modal