import PlayButton from '../../../../assets/icons/play-button.svg'

import './VideoCard.css'

function VideoCard({ imgUrl, title, handleClick }) {
    return (
        <button className='video_card' onClick={handleClick}>
            <div className="img_box play_icon_box">
                <img className='w_100' src={imgUrl} alt="thumb" />
                <img src={PlayButton} alt="icon" className='play_icon' />
            </div>
            <h4>{title}</h4>
        </button>
    )
}

export default VideoCard