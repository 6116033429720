import PlayButton from '../../../../../assets/icons/play-button.svg'

import './VideoListCard.css'

function VideoListCard({ event, imgUrl, date, title }) {
    return (
        <div className='video_list_card'>
            <button type='button' onClick={event}>
                <div className="grid_box">
                    <div className="img_box play_icon_box">
                        <img className='w_100' src={imgUrl} alt="thumb" />
                        <img src={PlayButton} alt="icon" className='play_icon' />
                    </div>
                    <div className="content">
                        <span>{date}</span>
                        <p>{title}</p>
                    </div>
                </div>
            </button>
        </div>
    )
}

export default VideoListCard