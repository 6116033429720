import BannerSlider from './BannerSlider/BannerSlider'
import AboutMe from './AboutMe/AboutMe'
import HomeBlog from './HomeBlog/HomeBlog'

import './Home.css'
import QuoteBanner from './AboutMe/QuoteBanner/QuoteBanner'
import HomeVideo from './HomeVideo/HomeVideo'

function Home() {
  return (
    <div className='home_page'>
        <BannerSlider />
        <AboutMe />
        <HomeVideo />
        <QuoteBanner />
        <HomeBlog />
    </div>
  )
}

export default Home