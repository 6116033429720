import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { BLOGS_API, IMAGE_URL } from '../../../Utilities/APIs/APIs';

import './SingleBlog.css'

function SingleBlog() {

  const { id } = useParams();

  const [singleBlog, setSingleBlog] = useState(null);

  useEffect(() => {
    async function getSingleBlogs() {
      const { data } = await axios.get(BLOGS_API + id)
      setSingleBlog(data)
    }

    getSingleBlogs()
  }, [id])

  return (
    <div className='single_blog page_padding'>
      <div className="container">
        <h1 className="sc_title">{singleBlog?.name}</h1>
        {/* <span className='date_time'>21 Feb, 2021 ; 10:20:15</span> */}
        <div className="img_box">
          <img src={IMAGE_URL + singleBlog?.image} alt="thumb" />
        </div>
        <div
          className="desc_box"
          dangerouslySetInnerHTML={{ __html: singleBlog?.description }}
        >
          {/* <p className="desc">International Mother Language Day (IMLD) is an observance that is celebrated annually on February 21st to promote linguistic and cultural diversity. It was first proclaimed by the United Nations Educational, Scientific and Cultural Organization (UNESCO) in 1999, and is intended to promote multilingualism and the use of mother languages as a medium of instruction in schools. The day is also a time to reflect on the importance of preserving endangered languages and promoting the linguistic rights of all people. The theme for International Mother Language Day changes every year, but generally focuses on the importance of linguistic and cultural diversity and the role of mother languages in education.</p>
          <p className="desc">"21st February event" refers to a specific event that is scheduled to occur on the 21st of February. Without more information, it is not possible to provide additional details about the event such as what it is, where it is happening, or what it will involve. It could be an event such as a conference, concert, festival, trade show, or political rally. If you provide more context or details about the event, I would be happy to provide more information.</p> */}
        </div>
      </div>
    </div>
  )
}

export default SingleBlog