import './VideoPlayer.css'

function VideoPlayer({videoUrl}) {
    return (
        <div className='video_player'>
            <iframe src={`https://www.youtube.com/embed/${videoUrl}`} title="video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
    )
}

export default VideoPlayer