import WhiteLogo from '../../../assets/images/logo-white.svg'

import Logo from '../../Elements/Logo/Logo'
import SocialLinks from '../../Layouts/SocialLinks/SocialLinks'

import './Footer.css'

function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="flex_box">
                    <div className="logo_wrapper">
                        <Logo imgSrc={WhiteLogo} />
                    </div>
                    <SocialLinks />
                </div>
                <div className="flex_box">
                    <h3>Made with ❤ by &nbsp;
                        <a href="https://theantopolis.com/">Antopolis</a>
                    </h3>
                </div>
            </div>
        </footer>
    )
}

export default Footer