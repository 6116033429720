import { backendUrl, spacesBucketUrl } from "../../Constants"

export const BASE_URL = backendUrl
export const IMAGE_URL = spacesBucketUrl
export const API_URL = BASE_URL + 'api/'


export const SLIDERS_API = API_URL + 'sliders/'
export const ABOUTS_API = API_URL + 'abouts/'
export const BLOGS_API = API_URL + 'blogs/'
export const FEATURE_BLOGS_API = API_URL + 'featuredBlogs/'
export const VIDEOS_API = API_URL + 'videos/'
export const VIDEO_CATEGORIES_API = API_URL + 'videoCategories/'
export const FEATURE_VIDEOS_API = API_URL + 'featuredVideos/'

export const SOCIAL_LINKS_API = API_URL + 'socialLinks/'
