import { useEffect, useState } from 'react'
import { RiMenuLine } from 'react-icons/ri'
import WhiteLogo from '../../../assets/images/logo-black.svg'
import Logo from '../../Elements/Logo/Logo'
import MenuLg from './MenuLg/MenuLg'
import MenuSm from './MenuSm/MenuSm'

import './Navbar.css'

function Navbar() {

  const [showMenu, setShowMenu] = useState(false);
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10)
    })
  }, []);

  const toggleMenu = () => {
    setShowMenu(prevState => !prevState)
  }

  return (
    <>
      <section className={`navbar ${scroll && 'bg_dark'}`}>
        <div className="container">
          <div className="flex_box">
            <div className="logo_wrapper">
              <Logo imgSrc={WhiteLogo} />
            </div>
            <div className="menu_lg_wrapper">
              <MenuLg />
            </div>
            <div className="menu_toggle">
              <button className='btn_menu_toggle' onClick={toggleMenu}>
                <RiMenuLine />
              </button>
            </div>
          </div>
        </div>
      </section>
      <MenuSm
      showMenu={showMenu}
        handleClick={toggleMenu}
      />
    </>
  )
}

export default Navbar