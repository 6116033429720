import { useEffect, useState } from 'react'
// import SvideoImg from '../../../../assets/images/video-img-1.png'
// import VLImg1 from '../../../../assets/images/video-img-1.png'

import SingleVideoCard from './SingleVideoCard/SingleVideoCard'
import VideoListCard from './VideoListCard/VideoListCard'

import './HomeVideo.css'
import Modal from '../../../Layouts/Modal/Modal'
import VideoPlayer from '../../../Elements/VideoPlayer/VideoPlayer'
import { FEATURE_VIDEOS_API, IMAGE_URL } from '../../../../Utilities/APIs/APIs'
import axios from 'axios'

function HomeVideo() {

    const [showPlayer, setShowPlayer] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [featureVideos, setFeatureVideos] = useState(null);

    useEffect(() => {
        async function getFeatureVideos() {
            const { data } = await axios.get(FEATURE_VIDEOS_API)
            setFeatureVideos(data)
        }

        getFeatureVideos()
    }, [])

    const handleSetUrl = (url) => {
        setVideoUrl(url);
        setShowPlayer(true);
    }
    const handleModalClose = (url) => {
        setVideoUrl('');
        setShowPlayer(false);
    }

    return (
        <>
            <section className="home_video">
                <div className="container">
                    <div className="grid_box">
                        <div className="grid_item">
                            <h1 className="sc_title">My Video <br /> <span>Gallery</span></h1>
                            {featureVideos &&
                                <SingleVideoCard
                                    event={() => handleSetUrl(featureVideos[0]?.video?.link)}
                                    imgUrl={IMAGE_URL + featureVideos[0]?.video?.image}
                                    date={featureVideos[0]?.video?.date}
                                    title={featureVideos[0]?.video?.name}
                                />
                            }
                        </div>
                        <div className="grid_item">
                            {featureVideos?.map(fVideo => (
                                <VideoListCard
                                    key={fVideo._id}
                                    event={() => handleSetUrl(fVideo?.video?.link)}
                                    imgUrl={IMAGE_URL + fVideo?.video?.image}
                                    date={fVideo?.video?.date}
                                    title={fVideo?.video?.name}
                                />
                            ))}
                            {/* <VideoListCard
                                event={() => handleSetUrl('uZFfNMfHHCA')}
                                imgUrl={VLImg1}
                                date='16 March, 2022'
                                title='Nam Elit Agna, Endrerit Sit Amet, Tincidunt Ac, Viverra'
                            />
                            <VideoListCard
                                event={() => handleSetUrl('qNSv9I0Flac')}
                                imgUrl={VLImg1}
                                date='16 March, 2022'
                                title='Nam Elit Agna, Endrerit Sit Amet, Tincidunt Ac, Viverra'
                            />
                            <VideoListCard
                                event={() => handleSetUrl('uZFfNMfHHCA')}
                                imgUrl={VLImg1}
                                date='16 March, 2022'
                                title='Nam Elit Agna, Endrerit Sit Amet, Tincidunt Ac, Viverra'
                            />
                            <VideoListCard
                                event={() => handleSetUrl('qNSv9I0Flac')}
                                imgUrl={VLImg1}
                                date='16 March, 2022'
                                title='Nam Elit Agna, Endrerit Sit Amet, Tincidunt Ac, Viverra'
                            />
                            <VideoListCard
                                event={() => handleSetUrl('uZFfNMfHHCA')}
                                imgUrl={VLImg1}
                                date='16 March, 2022'
                                title='Nam Elit Agna, Endrerit Sit Amet, Tincidunt Ac, Viverra'
                            /> */}
                        </div>
                    </div>
                </div>
            </section>
            {showPlayer &&
                <Modal
                    handleClose={handleModalClose}
                >
                    <VideoPlayer
                        videoUrl={videoUrl}
                    />
                </Modal>
            }
        </>
    )
}

export default HomeVideo