import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';
import { RiArrowRightSLine } from 'react-icons/ri'
import { ABOUTS_API, IMAGE_URL } from '../../../../Utilities/APIs/APIs';

import './AboutMe.css'

function AboutMe() {

    const [about, setAbout] = useState(null);

    useEffect(() => {
        async function getAbouts() {
            const { data } = await axios.get(ABOUTS_API)
            setAbout(data[0])
        }

        getAbouts()
    }, [])

    return (
        <section className='about_me'>
            <div className="container">
                <div className="grid_box">
                    <div className="content_box">
                        <h1 className="sc_title">Simplicity is the
                            <span>Ultimate</span> Sophistication</h1>
                        <div className="desc_box">
                            <p className="desc">
                                {about?.description}
                            </p>
                        </div>
                        <Link className='btn_more' to='/about'>
                            <span>see details</span>
                            <RiArrowRightSLine />
                        </Link>
                    </div>
                    <div className="image_box">
                        <img className='w_100' src={IMAGE_URL + about?.image} alt="thumb" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutMe