import { NavLink } from 'react-router-dom'

import './MenuLg.css'

function MenuLg() {
  return (
    <nav>
      <NavLink to='/'>Home</NavLink>
      <NavLink to='/about'>about me</NavLink>
      <NavLink to='/blogs'>blogs</NavLink>
      <NavLink to='/video-gallery'>My Video Gallery</NavLink>
    </nav>
  )
}

export default MenuLg