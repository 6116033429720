import { NavLink } from 'react-router-dom'
import { RiCloseLine } from 'react-icons/ri'

import './MenuSm.css'

function MenuSm({handleClick, showMenu}) {
  return (
    <>
      <div className={`menu_sm_overlary ${showMenu && 'show'}`} onClick={handleClick}></div>
      <div className={`menu_sm ${showMenu && 'show'}`}>
        <button className='btn_menu_close' onClick={handleClick}>
          <RiCloseLine />
        </button>
        <div className="menu_sm_links">
          <NavLink to='/' onClick={handleClick}>Home</NavLink>
          <NavLink to='/about' onClick={handleClick}>about me</NavLink>
          <NavLink to='/blogs' onClick={handleClick}>blogs</NavLink>
          <NavLink to='/video-gallery' onClick={handleClick}>My Video Gallery</NavLink>
        </div>
      </div>
    </>

  )
}

export default MenuSm