import { useEffect, useState } from 'react';
import axios from 'axios';
import { ABOUTS_API, IMAGE_URL } from '../../../Utilities/APIs/APIs';
// import AboutDetails from '../../../assets/images/about-details.png'

import './About.css'

function About() {

  const [about, setAbout] = useState(null);

  useEffect(() => {
    async function getAbouts() {
      const { data } = await axios.get(ABOUTS_API)
      setAbout(data[0])
    }

    getAbouts()
  }, [])

  return (
    <div className='about_page page_padding'>
      <div className="container">
        <h1 className="page_title">About <br /> me</h1>
        <div className="contents_wrapper">
          <img src={IMAGE_URL + about?.image} alt="thumb" className='big_thumb' />
          <p>{about?.description}</p>
        </div>
      </div>
    </div>
  )
}

export default About